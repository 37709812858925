<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-layout wrap>
              <v-flex align-self-center text-left>
                <span style="font-family: poppinssemibold"
                  >ORGANISATION TYPE</span
                >
              </v-flex>
              <v-flex xl3 lg3 md3 sm3 xs12 pb-2 pl-6 text-right>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  persistent
                  max-width="50%"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="font-family: poppinsmedium"
                      block
                      small
                      dark
                      class="rounded-xl text-none"
                      color="#5F4B8BFF"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil-plus-outline</v-icon>&nbsp; Add
                      Organisation Type
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form v-model="addcat" ref="addcat">
                      <!-- <v-card-title>
                       
                      </v-card-title> -->
                      <!-- <v-card-text> -->
                      <v-layout wrap>
                        <v-flex xs12 style="background-color: #5f4b8bff">
                          <span
                            style="
                              color: #ffffff;
                              font-size: 20px;
                              font-family: poppinsbold;
                              padding: 2px;
                            "
                            class="RB"
                            >ADD ORGANISATION TYPE</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-12 justify-center fill-height>
                         <v-flex xs12 align-self-center text-left>
                          <span>Organisation Type*</span>
                          <br />
                          <v-text-field
                            v-model="name"
                            placeholder="Organisation Type"
                            :rules="[rules.required]"
                            required
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <!-- </v-card-text> -->
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#ED2B33FF" text @click="dialogclose()">
                          Close
                        </v-btn>
                        <v-btn
                          color="#0063B2FF"
                          text
                          :disabled="!addcat"
                          @click="addOrganisation()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
            <v-progress-linear
              :color="appColor"
              rounded
              value="100"
              height="2px"
            ></v-progress-linear>
          </v-flex>

          <template v-if="organisations.length > 0">
            <v-flex
              xs12
              md6
              sm6
              lg3
              xl3
              align-self-center
              v-for="(item, i) in organisations"
              :key="i"
              fill-height
              pa-4
            >
              <v-card
                tile
                elevation="2"
                outlined
                style="
                  border-radius: 0px 0px 20px !important;
                  border-bottom: 5px solid #5f4b8bff;
                "
              >
                <v-layout wrap justify-center py-4>
               
                  <v-flex xs12 align-self-start text-center pa-3> 
                    <!-- <span> -->
                    <!-- <v-list-item-avatar>
                    <v-img contain :src="mediaURL + item.icon"></v-img>
                  </v-list-item-avatar> -->
                    <!-- </span> -->
                    <span style="font-family: poppinsmedium">{{
                      item.type
                    }}</span>
                  </v-flex>
                  <!-- </v-layout>
                <v-layout wrap justify-center py-4> -->
                  <v-flex xs12 sm4 pa-2>
                    <v-btn
                      color="#0063B2FF"
                      style="font-family: poppinsmedium"
                      x-small
                      block
                      tile
                      dark
                      @click="editcat(item)"
                      >Edit</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 sm4 pa-2>
                    <v-dialog
                      persistent
                      v-model="item.delete"
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          style="font-family: poppinsmedium"
                          class="mr-2"
                          dark
                          block
                          tile
                          v-on="on"
                          v-bind="attrs"
                          color="#ED2B33FF"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this Organisation
                          Type?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="#ED2B33FF"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="#0063B2FF"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template>
          <v-flex xs12 v-else>
            <span
              style="
                color: black;
                font-size: 12px;
                font-family: poppinsbold;
                padding: 2px;
              "
              class="RB"
            >
              Empty list.....!
            </span>
          </v-flex>

          <v-dialog persistent v-model="editdialog" max-width="50%">
            <v-card tile>
              <!-- <v-card-title>
                <span class="headline">Edit Organisation</span>
              </v-card-title> -->
              <!-- <v-card-text> -->
              <v-layout wrap>
                <v-flex xs12 style="background-color: #5f4b8bff">
                  <span
                    style="
                      color: #ffffff;
                      font-size: 20px;
                      font-family: poppinsbold;
                      padding: 2px;
                    "
                    class="RB"
                    >EDIT ORGANISATION</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap pa-12>
                <v-flex xs12 text-left>
                  Organisation Type*
                  <br />
                  <v-text-field
                    v-model="editingitem.type"
                    placeholder="Organisation Type"
                    :rules="[rules.required]"
                    outlined
                    dense
                    required
                    hide-details
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <!-- </v-card-text> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ED2B33FF" text @click="getdData"> Close </v-btn>
                <v-btn
                  v-if="editingitem.type"
                  color="#0063B2FF"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      organisations: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/organization/type/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/organization/type/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.organisations = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addOrganisation() {
      var data = {};
      data["type"] = this.name;
      axios({
        url: "/organization/type/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["type"] = this.editingitem.type;
      axios({
        url: "/organization/type/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
